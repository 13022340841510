import { patchItem, getItemById } from "@/../../shared/api/SharedClient"
import { setProperty, setPath } from '@/utils/vuexFunctionHelper'
import { formatBirthDateFromServerToDatePickerFormat } from '@/utils/dateTimeHelper'
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

import { defaultErrorMessage, orderSuccessAction } from "../../../../shared/constants/validationMessageHelper"

const orderModule = {
    namespaced: true,
    state: {
        order: null,
        originalOrder: null
    },
    getters: {
        getOrderId: (state) => {
            return state.order && state.order.id
        },
        getProviderReferenceId: (state) => {
            return (
                state.order &&
                state.order.providerReference &&
                state.order.providerReference.id
            )
        },
        getFirstOrderLine: (state) => {
            return (
                state.order &&
                state.order.orderLines &&
                state.order.orderLines[0]
            )
        }
    },
    mutations: {
        resetOrderState(state) {
            state.order = null
        },
        setOriginalOrder(state, order) {
            // copy the initial state of order details, only patch order if value has changed
            state.originalOrder = JSON.parse(JSON.stringify(order))
        },
        setOrder: setProperty('order'),
        // start moment ids
        setFirstChoiceStartMomentId: setPath([
            'order',
            'customAttributes',
            'firstChoiceStartMomentId'
        ]),
        setSecondChoiceStartMomentId: setPath([
            'order',
            'customAttributes',
            'secondChoiceStartMomentId'
        ]),
        // edit student details
        setCustomerPhoneNumber: setPath(['order', 'customer', 'phoneNumber']),
        setCustomerBirthCity: setPath(['order', 'customer', 'birthCity']),
        setCustomerBirthCode: setPath([
            'order',
            'customer',
            'birthCountryCode'
        ]),
        setCustomerBirthDate: setPath(['order', 'customer', 'birthDate']), 
        setInvoiceOnBehalfOfName(state, invoiceOnBehalfOfName) {
            const firstOrderLine =
                state.order &&
                state.order.orderLines &&
                state.order.orderLines[0]
            firstOrderLine.invoiceOnBehalfOf.name = invoiceOnBehalfOfName
        },
        setInvoiceOnBehalfOfVatNumber(state, invoiceOnBehalfOfVatNumber) {
            const firstOrderLine =
                state.order &&
                state.order.orderLines &&
                state.order.orderLines[0]
            firstOrderLine.invoiceOnBehalfOf.vatNumber = invoiceOnBehalfOfVatNumber
        },
        setInvoiceOnBehalfOfMerchantId(state, invoiceOnBehalfOfMerchantId) {
            const firstOrderLine =
                state.order &&
                state.order.orderLines &&
                state.order.orderLines[0]
            firstOrderLine.invoiceOnBehalfOf.merchantId = invoiceOnBehalfOfMerchantId
        }
    },
    actions: {
        async getOrder({
            commit
        }, id) {
            if (!id) return
            const response = await getItemById(
                process.env.VUE_APP_ORDER_API_URL,
                API_CALL_URL_PATHS.orders,
                id,
                null,
                false
            )
            try {
                if (!response) return
                
                if(!response.customAttributes.secondChoiceStartMomentId) {
                    response.customAttributes.secondChoiceStartMomentId = null
                }

                formatBirthDateFromServerToDatePickerFormat(response)
                commit('resetOrderState')
                commit('setOrder', response)
                commit('setOriginalOrder', response)
            } catch (error) {
                console.error(
                    'Something went wrong while retrieving order details',
                    error
                )
            }
        },
        patchOrder({
            commit,
            dispatch
        }, {
            orderId,
            objectToBePatched
        }) {
            return patchItem(
                    process.env.VUE_APP_ORDER_API_URL,
                    API_CALL_URL_PATHS.orders,
                    orderId,
                    objectToBePatched,
                    false
                )
                .then((response) => {
                    if (!response) return
                    formatBirthDateFromServerToDatePickerFormat(response)
                    commit('setOriginalOrder', response) 
                    commit('setOrder', response)
                    
                    const toastNotification = {
                        type: 'success',
                        message: orderSuccessAction('bewerkt')
                    }                    
                    
                    dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    }) 

                    return response
                    
                })
                .catch(() => {  
                    const toastNotification = {
                        type: 'error',
                        message: defaultErrorMessage
                    }                    
                    
                    dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    }) 
                })
        }
    }
}

export default orderModule