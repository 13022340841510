<template>
    <div>
        <recess-divider variant="large" show-line />

        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <h2 class="qa-order-general-details-title">
                    {{ PROVIDER.Order.OrderDetails.GeneralInfo.Title.Label }}
                </h2>
            </div>
        </div>

        <form @submit.prevent="submitHandler">
            <div class="row">
                <div class="col-4">
                    <div class="qa-order-name">
                        <h5 class="qa-order-name-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.Name.Label }}</h5>
                        <p class="qa-order-name-value">{{ order.name }}</p>
                    </div>

                    <div class="qa-order-product-type">
                        <h5 class="qa-order-product-type-label">
                            {{ PROVIDER.Order.OrderDetails.GeneralInfo.ProductTypeDisplayValue.Label }}
                        </h5>
                        <p class="qa-order-product-type-value">{{ order.productTypeDisplayValue || '-' }}</p>
                    </div>

                    <div class="qa-order-number">
                        <h5 class="qa-order-number-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.Number.Label }}</h5>
                        <p class="qa-order-number-value">{{ order.orderNumber }}</p>
                    </div>

                    <div class="qa-order-date-created">
                        <h5 class="qa-order-date-created-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.DateCreated.Label }}</h5>
                        <p class="qa-order-date-created-value">{{ order.dateCreated | formatDate }}</p>
                    </div>

                    <div class="qa-order-type">
                        <h5 class="qa-order-type-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.LearningMethod.Label }}</h5>
                        <p class="qa-order-type-value">{{ order.learningMethodFormatDisplayValue || '-' }}</p>
                    </div>

                    <div class="qa-order-account-name">
                        <h5 v-if="order.accountReference" class="qa-order-account-name-label">
                            {{ PROVIDER.Order.OrderDetails.GeneralInfo.AccountReference.Label }}
                        </h5>
                        <p class="qa-order-account-name-value">{{ order.accountName || '-' }}</p>
                    </div>

                    <div v-if="order.studentExternalCompany" class="qa-order-student-external-company">
                        <h5 class="qa-order-student-external-company-label">
                            {{ PROVIDER.Order.OrderDetails.GeneralInfo.StudentExternalCompany.Label }}
                        </h5>
                        <p class="qa-order-student-external-company-value">
                            {{ order.studentExternalCompany }}
                        </p>
                    </div>

                    <div v-if="order.costCentre" class="qa-order-cost-center">
                        <h5 class="qa-order-cost-center-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.CostCenter.Label }}</h5>
                        <p class="qa-order-cost-center-value">{{ order.costCentre }}</p>
                    </div>
                </div>

                <template v-if="order.customAttributes">
                    <div class="col-4 qa-order-first-start-moment">
                        <h5 class="qa-order-first-start-moment-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.StartMoment1.Label }}</h5>

                        <!-- show dropdown to change to another start moment date & place -->
                        <template v-if="allowEditingOrderStatus && !displayAcceptedStartmoment">
                            <form @submit.prevent="submitHandler">
                                <recess-multi-select
                                    :selected-options="firstStartMomentValue"
                                    :single-select="true"
                                    :options="startMomentOptions"
                                    :select-options-text="PROVIDER.Order.OrderDetails.GeneralInfo.StartMomentSelectOptionsText.SelectOption"
                                    class="mb-3 qa-change-first-start-moment-id"
                                    :error-message="sameStartMomentIdError('firstChoiceStartMomentId')"
                                    @input="(newValue) => setFirstChoiceStartMoment(newValue)"
                                />
                            </form>
                        </template>

                        <template v-else>
                            <h5 class="qa-order-first-start-moment-date-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.Date.Label }}</h5>
                            <p class="qa-order-first-start-moment-date-value">
                                {{ getStartMomentDate(firstStartMomentValue, {day: order.startMomentDay, month: order.startMomentMonth, year: order.startMomentYear}) }}
                
                            </p>

                            <h5 class="qa-order-first-start-moment-location-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.Location.Label }}</h5>
                            <p class="qa-order-first-start-moment-location-value">
                                {{ getStartMomentLocation(firstStartMomentValue, order.startMomentLocation) }}
                            </p>
                        </template>

                        <h5 class="qa-order-first-start-moment-id-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.Id.Label }}</h5>
                        <p class="qa-order-first-start-moment-id-value">
                            {{ showNvtForElearningCourse(order.customAttributes.firstChoiceStartMomentId) }}
                        </p>

                        <h5 class="qa-order-start-guaranteed-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.StartGuaranteed.Label }}</h5>
                        <img v-if="order.startMomentStartGuaranteed" src="../../../assets/images/icon-check.svg" alt="start-guaranteed" class="qa-order-start-guaranteed" />
                        <p v-else class="qa-order-start-guaranteed-value">
                            {{ showNvtForElearningCourse('-') }}
                        </p>

                        <h5 class="qa-order-subsidy-label">Subsidie</h5>
                        <p class="qa-order-subsidy-value">
                            {{ order.subsidyDisplayValue ?? '-' }}
                        </p>

                        <div v-if="order.acceptedStartMomentId" class="qa-order-accepted-startmoment">
                            <h5 class="qa-order-accepted-startmoment-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.AcceptedStartMoment.Label }}</h5>
                            <p class="qa-order-accepted-startmoment-value">{{ displayAcceptedStartmoment }}</p>
                        </div>
                    </div>

                    <div class="col-4 qa-order-second-start-moment">
                        <h5 class="qa-order-second-start-moment-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.StartMoment2.Label }}</h5>

                        <!-- show dropdown to change to another start moment date & place -->
                        <template v-if="allowEditingOrderStatus && !displayAcceptedStartmoment">
                            <recess-multi-select
                                :selected-options="secondStartMomentValue"
                                :single-select="true"
                                :options="startMomentOptions"
                                :error-message="sameStartMomentIdError('secondChoiceStartMomentId')"
                                :select-options-text="PROVIDER.Order.OrderDetails.GeneralInfo.StartMomentSelectOptionsText.SelectOption"
                                class="mb-3 qa-change-second-start-moment-id"
                                @input="(newValue) => setSecondChoiceStartMoment(newValue)"
                            />
                        </template>

                        <template v-else>
                            <h5 class="qa-order-second-start-moment-date-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.Date.Label }}</h5>
                            <p class="qa-order-second-start-moment-date-value">
                                {{ getStartMomentDate(secondStartMomentValue,  {day: order.secondStartMomentDay, month: order.secondStartMomentMonth, year: order.secondStartMomentYear}) }}
                            </p>
                            <h5 class="qa-order-second-start-moment-location-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.Location.Label }}</h5>
                            <p class="qa-order-second-start-moment-location-value">
                                {{ getStartMomentLocation(secondStartMomentValue, order.secondStartMomentLocation) }}
                            </p>
                        </template>

                        <h5 class="qa-order-second-start-moment-id-label">{{ PROVIDER.Order.OrderDetails.GeneralInfo.Id.Label }}</h5>
                        <p class="qa-order-second-start-moment-id-value">
                            {{ showNvtForElearningCourse(order.customAttributes.secondChoiceStartMomentId) }}
                        </p>
                    </div>
                </template>
            </div>

            <div class="row" v-if="allowEditingOrderStatus && !order.acceptedStartMomentId">
                <div class="col-12 d-flex justify-content-end align-items-center flex-wrap">
                    <recess-button :title="PROVIDER.Shared.Save" type="submit" variant="secondary" class="qa-submit-edit-order-start-moments" />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { validationMixin } from 'vuelidate'

import { formatUtcDateTimeToLocalDate } from '@/utils/dateTimeHelper'

import { ERROR_MESSAGES } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { buildDate } from '../../../../../shared/helpers/date-helper'

export default {
    name: 'OrderGeneralInfoComponent',
    mixins: [validationMixin],
    props: {
        courseStartMomentsOptions: {
            type: Array,
            default: () => {
                return {}
            }
        },
        allowEditingOrderStatus: {
            type: Boolean,
            default: false
        }
    },
    validations: {
        order: {
            customAttributes: {
                firstChoiceStartMomentId: {
                    isNotEqualOtherStartMoments(value, item) {
                        if (item.secondChoiceStartMomentId) {
                            return value !== item.secondChoiceStartMomentId
                        }
                        return true
                    }
                },
                secondChoiceStartMomentId: {
                    isNotEqualOtherStartMoments(value, item) {
                        if (item.firstChoiceStartMomentId) {
                            return value !== item.firstChoiceStartMomentId
                        }
                        return true
                    }
                }
            }
        }
    },
    data() {
        return {
            PROVIDER,
            orderId: this.$route.params.id || null,
            startMomentOptions: [],
            firstStartMomentValue: '',
            secondStartMomentValue: ''
        }
    },
    computed: {
        ...mapState('orderModule', ['order', 'originalOrder']),
        isELearningCourse() {
            return this.order && this.order.learningMethodName === PROVIDER.Order.Shared.LearningMethodName.SelfPlanned
        },
        hasCustomAttributes() {
            return this.order?.customAttributes?.firstChoiceStartMomentId || this.order?.customAttributes?.secondChoiceStartMomentId
        },
        setObjectToBePatched() {
            const patchProperties = ['firstChoiceStartMomentId', 'secondChoiceStartMomentId']
            const array = []

            patchProperties.forEach((property) => {
                if (!this.originalOrder.customAttributes[property] && this.order.customAttributes[property]) {
                    return array.push({
                        value: this.order.customAttributes[property],
                        path: `customAttributes/${property}`,
                        op: 'add'
                    })
                }

                if (this.order.customAttributes[property] !== this.originalOrder.customAttributes[property]) {
                    return array.push({
                        value: this.order.customAttributes[property],
                        path: `customAttributes/${property}`,
                        op: 'replace'
                    })
                }
            })

            return array
        },
        displayAcceptedStartmoment() {
            if (!this.hasCustomAttributes) return

            let formatAcceptedStartmoment

            if (this.order.acceptedStartMomentId) {
                if (this.order.acceptedStartMomentId === this.order.customAttributes.firstChoiceStartMomentId) {
                    formatAcceptedStartmoment = this.displayFormatFirstStartmoment
                }

                if (this.order.acceptedStartMomentId === this.order.customAttributes.secondChoiceStartMomentId) {
                    formatAcceptedStartmoment = this.displayFormatSecondStartmoment
                }

                formatAcceptedStartmoment = this.displayFormatAcceptedStartmoment
                
            }

            return formatAcceptedStartmoment
        },
        displayFormatFirstStartmoment() {
            return `${buildDate(
                this.order.startMomentDay ? this.formatDateAndMonthToTwoDigits(this.order.startMomentDay) : '01',
                this.formatDateAndMonthToTwoDigits(this.order.startMomentMonth),
                this.order.startMomentYear
            )} - ${this.order.startMomentLocation}`
        },
        displayFormatSecondStartmoment() {
            return `${buildDate(
                this.order.secondStartMomentDay ? this.formatDateAndMonthToTwoDigits(this.order.secondStartMomentDay) : '01',
                this.formatDateAndMonthToTwoDigits(this.order.secondStartMomentMonth),
                this.order.secondStartMomentYear
            )} - ${this.order.secondStartMomentLocation}`
        },
        displayFormatAcceptedStartmoment() {
            return `${formatUtcDateTimeToLocalDate(this.order.acceptedStartMomentDate)} - ${this.order.acceptedStartMomentLocation}`
        },
        isValidOnSumbit() {
            return !this.$v.$invalid && JSON.stringify(this.order) !== JSON.stringify(this.originalOrder)
        }
    },
    watch: {
        courseStartMomentsOptions: {
            handler() {
                if (this.courseStartMomentsOptions === null) return
                this.setStartMomentOptions()
            }
        }
    },
    methods: {
        ...mapActions('courseModule', ['fetchCourse']),
        ...mapActions('orderModule', ['patchOrder', 'getOrder']),
        ...mapMutations('orderModule', ['setFirstChoiceStartMomentId', 'setSecondChoiceStartMomentId']),
        formatDateAndMonthToTwoDigits(value) {
            if (value > 0 && value < 10) {
                return String(value).padStart(2, '0')
            }
            return value
        },
        sameStartMomentIdError(property) {
            if (!this.hasCustomAttributes) return

            if (this.$v.order.customAttributes[property] && !this.$v.order.customAttributes[property].isNotEqualOtherStartMoments) {
                return ERROR_MESSAGES.isSameStartMomentId
            }
            return null
        },
        setFirstChoiceStartMoment(newValue) {
            this.firstStartMomentValue = newValue
            this.setFirstChoiceStartMomentId(newValue)
        },
        setSecondChoiceStartMoment(newValue) {
            this.secondStartMomentValue = newValue
            this.setSecondChoiceStartMomentId(newValue)
        },
        setStartMomentOptions() {
            if (!this.hasCustomAttributes) return

            this.firstStartMomentValue = this.order.customAttributes.firstChoiceStartMomentId
            this.secondStartMomentValue = this.order.customAttributes.secondChoiceStartMomentId

            if (this.order.customAttributes && this.order.customAttributes.firstChoiceStartMomentId && this.order.startMomentMonth && this.order.startMomentYear && this.order.startMomentLocation) {
                this.startMomentOptions.push({
                    value: this.order.customAttributes.firstChoiceStartMomentId,
                    displayText: this.displayFormatFirstStartmoment
                })
            }

            if (
                this.order.customAttributes &&
                this.order.customAttributes.secondChoiceStartMomentId &&
                this.order.secondStartMomentMonth &&
                this.order.secondStartMomentYear &&
                this.order.secondStartMomentLocation
            ) {
                this.startMomentOptions.push({
                    value: this.order.customAttributes.secondChoiceStartMomentId,
                    displayText: this.displayFormatSecondStartmoment
                })
            }

            if (this.courseStartMomentsOptions !== null || this.courseStartMomentsOptions.length > 0) {
                const filteredStartmoments = this.courseStartMomentsOptions.reduce((acc, startMoment) => {
                    if (!this.startMomentOptions.find((option) => option.value === startMoment.value)) {
                        return [...acc, startMoment]
                    }
                    return acc
                }, [])

                if (filteredStartmoments.length > 0) {
                    this.startMomentOptions = [...this.startMomentOptions, ...filteredStartmoments]
                }
            }
        },
        getStartMomentDate(value, defaultDate) {
            if (value){
                const startmomentObject = this.startMomentOptions.filter(options => options.value === value)
                const startmomentDate = startmomentObject[0].displayText.split('-')
                return this.setStartMomentDate(startmomentDate[0], startmomentDate[1], startmomentDate[2])
            }

            return this.setStartMomentDate(defaultDate.day, defaultDate.month, defaultDate.year)
        },
        setStartMomentDate(day, month, year) {
            if (this.isELearningCourse) return PROVIDER.Order.OrderDetails.Nvt
            if (!day && !month && !year) return '-'
            if (!day) return `${this.formatDateAndMonthToTwoDigits(month)}-${year}`
            return `${this.formatDateAndMonthToTwoDigits(day)}-${this.formatDateAndMonthToTwoDigits(month)}-${year}`
        },
        getStartMomentLocation(value, locationProperty){
            if(value) {
                const startmomentObject = this.startMomentOptions.filter(options => options.value === value)
                const startmomentDate = startmomentObject[0].displayText.split('-')
                return this.showNvtForElearningCourse(startmomentDate[startmomentDate.length-1])
            }

            return this.showNvtForElearningCourse(locationProperty)
        },
        showNvtForElearningCourse(property) {
            return this.isELearningCourse ? PROVIDER.Order.OrderDetails.Nvt : property || '-'
        },
        cancelHandler() {
            this.firstStartMomentValue = this.originalOrder.customAttributes.firstChoiceStartMomentId
            this.secondStartMomentValue = this.originalOrder.customAttributes.secondChoiceStartMomentId
            this.getOrder(this.orderId)
        },
        async submitHandler() {
            let promises = []

            this.$v.$touch()
            if (this.isValidOnSumbit && this.order && this.order.id) {
                promises.push(
                    this.patchOrder({
                        orderId: this.order.id,
                        objectToBePatched: this.setObjectToBePatched
                    })
                )

                promises.push(this.getOrder(this.orderId))

                await Promise.all(promises)
            }
        }
    }
}
</script>
