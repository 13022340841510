<template>
	<form @submit.prevent="submitHandler">
		<recess-modal :show-modal="isModalVisible" @close="close">
			<template slot="modal-title">
				<h3>{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.Title }} {{ orderNumber }}</h3>

				<span class="u-fw-semi-bold mb-5 qa-accept-order-popup-subsidy" v-if="hasOrderSubsidy"> {{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.SubsidyLabel }}: {{hasOrderSubsidy}}</span>
			</template>

			<template slot="modal-body">
				<div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
					<label class="w-30">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompany.Label }}</label>
					<recess-multi-select
						:selected-options="selectedLegalCompanyId"
						:options="legalCompanyOptions"
						:search-input="true"
						:single-select="true"
						class="w-70 qa-legal-company-select"
						:placeholder="PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompany.Placeholder"
						@input="(newValue) => legalCompanySelectHandler(newValue)"
						@close="onTouch"
					/>
					<p v-if="!isAdministrator" class="mt-3 font-italic">
						{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompanyButton.PartOne }}
						<recess-button
							:title="PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompanyButton.Title"
							variant="variant4"
							class="mb-4"
							url="/leveranciersgegevens/handelsnamen"
						/>
						{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompanyButton.PartTwo }}
					</p>
				</div>

				<!-- if user selects a LC, we get LC by id and prefill the values in the input fields -->
				<template v-if="selectedLegalCompanyId && legalCompanyDetails">
					<legal-company-detail :legal-company-details="legalCompanyDetails" />
				</template>

				<div class="mb-2">
					<h4 class="d-block mb-3">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.Startmoment.Label }}</h4>
				
					

					<div v-if="hasStartmoments">  
						<recess-radio-buttons class="w-100 d-flex justify-content-between">
							<recess-radio-input
								v-for="startMomentType in startMomentTypes"
								:key="startMomentType.value"
								name="startMomentRadioButton"
								styling-variant="secondary"
								:class="`  mb-4 qa-startmoment-type-${startMomentType.value}`" 
								:value="startMomentType.value"
								:label-text="startMomentType.displayText"
								:default-checked="checkStartMomentType(startMomentType)"
								@input="(newValue) => setStartMomentType(newValue)"
							/>
						</recess-radio-buttons>  
						

						<div class="d-flex justify-content-between">
							<recess-multi-select 
								v-show="selectedStartMomentType === 'orderStartMoments'"
								:selected-options="chosenStartmoment"
								:options="orderStartmomentsOptions"
								:single-select="true" 
								class="w-50 qa-order-chosen-startmoment"
								:select-options-text="PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.Startmoment.Placeholder"
								@input="(newValue) => setChosenStartMoment(newValue)"
							/>
							
							<recess-multi-select 
								v-show="selectedStartMomentType === 'courseStartMoments'" 
								:selected-options="chosenStartmoment"
								:options="alternativeStartmomentsOptions"
								:single-select="true"  
								class="w-50 qa-order-chosen-startmoment"
								:select-options-text="PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.Startmoment.Alternative"
								@input="(newValue) => setChosenStartMoment(newValue)"
							/>
						</div>
						 
					</div>
					<p v-else class="w-70">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.Startmoment.NoStartmoments }}</p>
 

					<div class="mt-5 qa-accept-order-providers-responsibility-text">
						<h6 class="u-fw-semi-bold">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.ProvidersResponsibility.Header }}</h6>
						<p class="m-0">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.ProvidersResponsibility.P1 }}</p>
						<p class="m-0">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.ProvidersResponsibility.P2 }}</p>
						<p class="m-0">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.ProvidersResponsibility.P3 }}</p>
					</div>
				</div>
			</template>

			<template slot="modal-footer">
				<div class="d-flex justify-content-end">
					<recess-button variant="tertiary" :title="BUTTON_TEXT.cancel" class="qa-order-cancel-button" @click.native.prevent="close()" />
					<recess-button 
						variant="secondary" 
						type="submit" 
						:disabled="isValidForSubmit" 
						:title="BUTTON_TEXT.submit" 
						class="ml-3 qa-order-submit-button" 
					/>
				</div>
			</template>
		</recess-modal>
	</form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { patchItem, postItem, getItems, getItemById } from '@/../../shared/api/SharedClient'

import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { API_CALL_URL_PATHS, BUTTON_TEXT, WAIT_TIME } from '@/../../shared/constants/constantsGeneral.json'
import UserService from '../../../../shared/services/UserService'

import { formatToLocalDutchDate } from '@/utils/dateTimeHelper'
import { defaultErrorMessage, legalCompanyMissingCreditNumberError, legalCompanyMissingVatNumberError, orderSuccessAction } from '../../../../shared/constants/validationMessageHelper'
 

const LegalCompanyDetail = () => import('@/components/molecules/shared/LegalCompanyDetail')

export default {
	name: 'AcceptOrderPopupComponent',
	components: {
		LegalCompanyDetail
	},
	props: {
		isModalVisible: {
			type: Boolean,
			default: false
		},
		startmomentSelection: {
			type: Object,
			default: () => {
				return {}
			}
		},
		orderNumber: {
			type: String,
			default: '-'
		},
		providerId: {
			type: String,
			default: null
		} 
	},
	data() {
		return {
			PROVIDER,
			userService: new UserService(),
			isAdministrator: false,
			BUTTON_TEXT,
			isTouched: false,
			selectedLegalCompanyId: null,
			legalCompanyList: [],
			legalCompanyDetails: null,
			hasErrorPatchOrderLines: false, 
			chosenStartmoment: '',
			setObjectToBePatched: null,
			orderStatusTransitionItem: this.getEmptyOrderStatusTransition(),
			startMomentTypes: [
				{
					displayText: 'Aangevraagde startmomenten',
					value: 'orderStartMoments'
				},
				{
					displayText: 'Afwijkende startmomenten (alleen in overleg met medewerker)',
					value: 'courseStartMoments'
				}
			],
			selectedStartMomentType: 'orderStartMoments',
			orderStartmomentsOptions: [],
			alternativeStartmomentsOptions: [],
			polling: false
		}
	},
	async created() {
		this.isAdministrator = await this.userService.isAdministrator()
	},
	computed: {
		...mapState('orderModule', ['order']),
		...mapGetters('orderModule', ['getProviderReferenceId']),
		legalCompanyOptions() {
			if (this.legalCompanyList) {
				return this.legalCompanyList.map((item) => {
					return {
						value: item.id,
						displayText: item.name
					}
				})
			}
			return null
		},
		legalCompanyFilter() {
			let filter = 'deleted eq false' 

			if(this.providerId) {
				filter += ` and providerId eq ${this.providerId}`
			}

			return filter
		},
		isLegalCompanyCreditNumberValid() {
			const legalCompany = this.legalCompanyList !== null && this.legalCompanyList.find((x) => x.id === this.selectedLegalCompanyId)

			if (!legalCompany) return

			return legalCompany.creditNumber !== null && legalCompany.creditNumber !== undefined
		},
		isLegalCompanyVatNumberValid() {
			const legalCompany = this.legalCompanyList !== null && this.legalCompanyList.find((x) => x.id === this.selectedLegalCompanyId)

			if (!legalCompany) return

			return legalCompany.vatNumber !== null && legalCompany.vatNumber !== undefined && legalCompany.vatNumber !== ''
		},
		hasStartmoments() { 
			return this.startmomentSelection !== null && (this.startmomentSelection.orderSecondStartmomentId || this.startmomentSelection.orderFirstStartmomentId)
		},
		hasOrderSubsidy() {
			return this.order?.subsidyDisplayValue
		},
		isValidForSubmit(){
			return !this.selectedLegalCompanyId || (this.hasStartmoments && !this.chosenStartmoment)
		}
	},
	watch: {
		isModalVisible: function watchPropsChange(newValue) {
			// reset data status when modal is closed by watching the value
			// change of isModalvisible from its parent 'OrderList'
 
			if (newValue === false) {
				this.isTouched = false
				this.hasErrorPatchOrderLines = false

				if (this.legalCompanyList.length > 1) {
					this.legalCompanyDetails = null
					this.selectedLegalCompanyId = null
				}
			}

			if(newValue === true) { 
				this.getLegalCompanyList() 
			}
		},
		providerId: {
			handler() {
				if (this.providerId) {
					this.getLegalCompanyList()
				}
			}
		},
		startmomentSelection: {
			handler() {
				if (this.startmomentSelection !== null) {
					this.composeOrderStartmoments()
				}
			}
		},
		legalCompanyList: {
			handler() {
				if (this.legalCompanyList.length === 1) {
					this.legalCompanySelectHandler(this.legalCompanyList[0].id)
				}
			}
		}
	},
	methods: {
		...mapActions('orderModule', ['patchOrder', 'getOrder']),	
		setStartMomentType(newValue){
			this.selectedStartMomentType = newValue
			this.chosenStartmoment = null
		}, 
		checkStartMomentType(startMomentType) {
			if (startMomentType.value === this.selectedStartMomentType) {
				return startMomentType.value === this.selectedStartMomentType
			}

			return startMomentType.value === 'orderStartMoments'
		},
		formatDateAndMonthToTwoDigits(value) {
            if (value > 0 && value < 10) {
                return String(value).padStart(2, '0')
            }
            return value
        },
		setStartMomentDate(day, month, year) { 
            if (!day && !month && !year) return '-'
            if (!day) return `${this.formatDateAndMonthToTwoDigits(month)}-${year}`
            return `${this.formatDateAndMonthToTwoDigits(day)}-${this.formatDateAndMonthToTwoDigits(month)}-${year}`
        }, 

		close() {
			this.$emit('close')
			this.orderStartmomentsOptions = []
			this.chosenStartmoment = ''
		},
		onTouch() {
			this.isTouched = true
		},
		legalCompanySelectHandler(newValue) {
			this.selectedLegalCompanyId = newValue
			this.getLegalCompanyDetails()
		},

		showManualNotification(type, propertyErrorMessage) {
			const toastNotification = {
				type: type,
				message: propertyErrorMessage
			}

			this.$store.dispatch('toastNotificationModule/add', toastNotification, {
				root: true
			})
		},

		async submitHandler() {
			if (!this.isLegalCompanyCreditNumberValid) {
				this.showManualNotification('error', legalCompanyMissingCreditNumberError())
				return
			}

			if (!this.isLegalCompanyVatNumberValid) {
				this.showManualNotification('error', legalCompanyMissingVatNumberError)
				return
			}

			// approve order status
			this.orderStatusTransitionItem.status = PROVIDER.Order.Shared.OrderStatus.AcceptedProvider

			if (this.hasStartmoments) {
				await this.patchOrder({
					orderId: this.order.id,
					objectToBePatched: this.setObjectToBePatched
				})
			} 

			if(this.order.status !== PROVIDER.Order.Shared.OrderStatus.AcceptedProvider) {
				await this.pollOrderStatus()
			} else {
				this.getOrdersAfterRebuildOrderIndex(WAIT_TIME.indexRebuild)
				this.close()
			}
		},
		getOrdersAfterRebuildOrderIndex(millisecond) {
			this.timeout = setTimeout(() => this.$bus.emit('refresh-orders'), millisecond)
		},
		async postOrderStatusTransition(orderId) {

			await postItem(
					process.env.VUE_APP_ORDER_API_URL, 
					`${API_CALL_URL_PATHS.orders}/${orderId}/status-transitions`, 
					this.orderStatusTransitionItem, 
					false
				)
				.then((response) => {
					if (!response) return 

					this.showManualNotification('success', orderSuccessAction('goedgekeurd')) 
				})
				.catch((error) => { 
					this.showManualNotification('error', defaultErrorMessage)
 
					console.error('Something went wrong while posting order status', error)
				})
		},
		async getLegalCompanyList() {
			try {
				const response = await getItems(
					process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.legalCompanies, 
					1, 
					99999, 
					'name asc', 
					this.legalCompanyFilter, 
					false
				)
				if (!response) return
				this.legalCompanyList = response.items
			} catch (error) {
				console.error('Something went wrong while retrieving legal company list', error)
			}
		},
		async getLegalCompanyDetails() {
			if (!this.selectedLegalCompanyId) return
			try {
				const response = await getItemById(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.legalCompanies, this.selectedLegalCompanyId, null, false)
				if (!response) return
				this.legalCompanyDetails = response
			} catch (error) {
				console.error('Something went wrong while retrieving legal companies', error)
			}
		}, 
		async patchOrderLinesForEachSequenceNumber(orderId, orderLineSequenceNumber) {
			const patchObjList = []

			const invoiceOnBehalfOfNameObj = {
				value: this.legalCompanyDetails.name,
				path: 'invoiceOnBehalfOfName',
				op: 'replace'
			}

			patchObjList.push(invoiceOnBehalfOfNameObj)

			const invoiceOnBehalfOfVatNumberObj = {
				value: this.legalCompanyDetails.vatNumber,
				path: 'invoiceOnBehalfOfVatNumber',
				op: 'replace'
			}

			patchObjList.push(invoiceOnBehalfOfVatNumberObj)

			const invoiceOnBehalfOfMerchantIdObj = {
				value: this.legalCompanyDetails.merchantId,
				path: 'invoiceOnBehalfOfMerchantId',
				op: 'replace'
			}

			patchObjList.push(invoiceOnBehalfOfMerchantIdObj)

			await patchItem(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.orders, `${orderId}/lines/${orderLineSequenceNumber}`, patchObjList, false).then((response) => {
				if (!response) return
			})
		},
		setChosenStartMoment(newValue) {
			this.chosenStartmoment = newValue 

			this.setObjectToBePatched = [
				{
					value: this.chosenStartmoment,
					path: 'acceptedStartMomentId',
					op: 'replace'
				}
			]
		},
		composeOrderStartmoments() {
			if (!this.hasStartmoments) return

			let firstStartmoment
			let secondStartmoment

			if (this.startmomentSelection.orderFirstStartmomentId !== null) {
				firstStartmoment = {
					value: this.startmomentSelection.orderFirstStartmomentId,
					displayText: `${formatToLocalDutchDate(this.startmomentSelection.orderFirstStartmomentDate)} - ${this.startmomentSelection.orderFirstChoiceStartMomentLocation}`
				}

				this.orderStartmomentsOptions = [...this.orderStartmomentsOptions, firstStartmoment]
			}

			if (this.startmomentSelection.orderSecondStartmomentId !== null) {
				secondStartmoment = {
					value: this.startmomentSelection.orderSecondStartmomentId,
					displayText: `${formatToLocalDutchDate(this.startmomentSelection.orderSecondStartmomentDate)} - ${this.startmomentSelection.orderSecondChoiceStartMomentLocation}`
				}

				this.orderStartmomentsOptions = [...this.orderStartmomentsOptions, secondStartmoment]
			}

			if (this.order && this.order.acceptedStartMomentId !== null) {
				this.setChosenStartMoment(this.order.acceptedStartMomentId)
				return
			}

			if(this.orderStartmomentsOptions.length === 1) {
				this.setChosenStartMoment(this.orderStartmomentsOptions[0].value)
			}

			this.filterStartmomentsOptions()
		},

		filterStartmomentsOptions() { 
			if(!this.startmomentSelection.courseStartmomentsOptions) return  

			const filteredStartmoments = this.startmomentSelection.courseStartmomentsOptions.reduce((acc, startMoment) => {
				if (!this.orderStartmomentsOptions.find((option) => option.value === startMoment.value)) {   
					return [...acc, startMoment]
				}
				return acc
			}, []) 
			 
			this.alternativeStartmomentsOptions = filteredStartmoments
		}, 
		getEmptyOrderStatusTransition() {
			return {
				status: null,
				cancellationReason: null,
				message: null
			}
		},
		async pollOrderStatus() {
			this.polling = true;
			const poll = async () => {
				if (!this.polling) return
				try {
					await this.fetchOrderStatus()
					if (this.order.status === PROVIDER.Order.Shared.OrderStatus.PendingProvider) {
						this.polling = false;
						// patch order to include 'invoice on behalf of' information that allows order status change afterwards 
						for (const orderLine of this.order.orderLines) {
							await this.patchOrderLinesForEachSequenceNumber(this.order.id, orderLine.sequenceNumber)
						}
						await this.postOrderStatusTransition(this.order.id);
						this.getOrdersAfterRebuildOrderIndex(WAIT_TIME.indexRebuild)
						this.close()
					}  else if (this.order.status === "Invalid") {
						console.error("Order status is Invalid. Stopping polling.");
						this.polling = false;
					} else {
						setTimeout(poll, WAIT_TIME.indexRebuild);
					}
				} catch (error) {
					console.error("Error polling order status:", error);
					this.polling = false; // Stop polling on error
				}
			}
			poll();
		},
		async fetchOrderStatus() {
			await this.getOrder(this.order.id)
    	}
	},
	destroyed() {
		this.polling = false;
	}
}
</script>
