var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"o-layout o-layout--medium"},[_c('div',{staticClass:"container-fluid"},[(!_vm.isOnCourseCreatePage)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('recess-button',{staticClass:"qa-go-back-button",attrs:{"title":_vm.BUTTON_TEXT.goBack,"icon":"back","variant":"variant4","url":"/portfolio/overview"}})],1)]):_vm._e(),_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-10"},[_c('h2',{staticClass:"u-text-wrap"},[_vm._v(_vm._s(this.course.name))])]),(!_vm.isOnCourseCreatePage && !_vm.isOnCourseDuplicatePage)?_c('div',{staticClass:"col-2"},[(!_vm.course.deleted)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('recess-tooltip',[_c('template',{slot:"content"},[_vm._v(" "+_vm._s(_vm.PROVIDER.Portfolio.CourseEdit.DuplicateIcon.Tooltip))]),_c('span',{staticClass:"c-icon c-icon__copy qa-copy-details-course",on:{"click":function($event){return _vm.copyCourseHandler(_vm.course.id)}}})],2),_c('span',{staticClass:"c-icon c-icon__bin qa-delete-course ml-4",on:{"click":function($event){return _vm.showDeleteCourseModal(_vm.getCourseId)}}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-12"},[_c('h4',{staticClass:"mt-2"},[_vm._v(_vm._s(this.course.externalId))])])]),_c('div',{staticClass:"row"},[_c('tab-navigation',{staticClass:"col-12",attrs:{"page-name":"portfolio","route-params-id":_vm.$route.params.id,"navigation-tabs":_vm.navigationTabs,"active-tab":_vm.activeTab,"has-errors":_vm.disableTabLink},on:{"setTab":_vm.setActiveTab}})],1),_c('div',{staticClass:"row"},[(_vm.course)?_c('div',{staticClass:"col-12"},[_c('keep-alive',[_c(_vm.activeTab,{tag:"component",attrs:{"planning-and-invoices-data":{
                        source: _vm.course.source,
                        productType: _vm.course.productType,
                        learningMethod: _vm.course.learningMethod,
                        subsidies: _vm.course.subsidies,
                        crebo: _vm.course.crebo,
                        croho: _vm.course.croho
                    },"startMomentsListOptions":{
                        setPaginationOptions: _vm.setPaginationOptions,
                        setTableOptions: _vm.setTableOptions,
                        filter: _vm.filter,
                        selectedFilters: _vm.selectedFilters,
                        copyID: _vm.copyID,
                        cityOptions: _vm.cityOptions
                    },"active-tab":_vm.activeTab},on:{"submitForm":_vm.submitCourseEdit,"createCourse":_vm.postCourse,"triggerProgressChange":_vm.triggerProgressChange,"setStartMoments":_vm.setStartMoments,"setInvoicesCourseValue":_vm.setInvoicesCourseValue,"tabChange":_vm.setActiveTab,"updateStartmomentList":_vm.updateStartmomentList,"updateFilters":_vm.updateFiltersHelper}})],1)],1):_vm._e()])]),(_vm.isEditDuplicatedCoursesVisible)?_c('edit-duplicated-course-popup-component',{attrs:{"course-id":_vm.getCourseId,"is-modal-visible":_vm.isEditDuplicatedCoursesVisible},on:{"close":_vm.closeEditDuplicatedCourse}}):_vm._e(),(_vm.isDeleteCourseModalVisible)?_c('delete-course-popup-component',{attrs:{"has-course-details":_vm.hasCourseDetails,"is-modal-visible":_vm.isDeleteCourseModalVisible},on:{"close":_vm.closeDeleteCourseModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }