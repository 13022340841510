import dayjs from 'dayjs'
import { helpers } from 'vuelidate/lib/validators'

const customParseFormat = require('dayjs/plugin/customParseFormat')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)

export const isAlpha = helpers.regex('isAlpha', /^[a-zA-Z_ ]*$/)

// accepts only alphabet characters & special characters (e.g. Provence-Alpes-Côte d'Azur, St. Catharines)
// disallows: numbers, consecutive non-alphabetical characters(e.g. A----B), periods should have a space (e.g. St.Catharines)
export const isValidCity = helpers.regex(
    'isValidCity',
    /^([a-zA-Z\u0080-\u024F.' ]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*(?:\s*\([a-zA-Z\u0080-\u024F.' -]+\))?$/
);

export const isValidDate = helpers.regex(
    'isValidDate',
    /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/
)

export const isValidMinYear = helpers.regex(
    'isValidMinYear',
    /^(3[01]|[12][0-9]|0?[1-9])-(1[012]|0?[1-9])-((?:19|20)\d{2})$/
)

export const isValidTime = helpers.regex('isValidTime', /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/)

export const isValidDateFormat = (eventDate) => dayjs(eventDate, 'DD-MM-YYYY', true)

export const isValidTimeFormat = (eventDate) => dayjs(eventDate, 'HH:mm', true)

export const isValidPositiveDecimalNumberIncludingZero = helpers.regex(
    'isValidPositiveDecimalNumberIncludingZero',
    /^\s*(?=.*[0-9])\d*(?:\.\d{1})?\s*$/
)

export const isValidPermanentEducationPoints = helpers.regex(
    'isValidPermanentEducationPoints',
    /^([0-9][0-9]{0,2}(?:\.\d{1})?|1000)$/ // allow range 0 - 1000 number with max 1 decimal place
)

export function sortByDate(startDate, endDate) {
    if (!startDate || !endDate) {
        return 1
    }

    const setStartDate = dayjs(startDate, 'DD-MM-YYYY')
    const setEndDate = dayjs(endDate, 'DD-MM-YYYY')
    return setEndDate.isAfter(setStartDate) ? -1 : 1
}

export function isEndDateBiggerThanStartDate(startDate, endDate) { 
    if (!startDate || !endDate || !isValidDate(startDate) || !isValidDate(endDate)) {
        return true
    }

    const setStartDate = dayjs(startDate, 'DD-MM-YYYY')
    const setEndDate = dayjs(endDate, 'DD-MM-YYYY')

    return setEndDate.isSameOrAfter(setStartDate)
}

export function getDate(dateString) {
    if (!dateString) return null
    if (!isValidDate(dateString)) return null

    return dayjs(dateString, 'DD-MM-YYYY')
}

export function isDateInThePast(dateString, referenceDateString = null) {
    if (!dateString) return true

    const date = getDate(dateString)
    const referenceDate = referenceDateString == null ? dayjs() : getDate(referenceDateString)

    return date.isBefore(referenceDate)
}

export function isDateInTheFuture(dateString, referenceDateString = null) { 
    if (!dateString || !dayjs(dateString, 'DD-MM-YYYY', true)) return true 
    if (!isValidDate(dateString)) return true
    
    const date = getDate(dateString)
    const referenceDate = referenceDateString == null ? dayjs() : getDate(referenceDateString)
 
    return date.isAfter(referenceDate)
}

export const isAllowedAge = (dateString, minAge, maxAge) => {
    const today = dayjs()
    const birthDate = dayjs(dateString, 'DD-MM-YYYY')
    const age = today.diff(birthDate, 'years')

    return age >= minAge && age <= maxAge
}

export const isPositiveNumber = helpers.regex(
    'isPositiveNumber',
    /^[0-9]*[1-9][0-9]*$/
)

export const isPhoneNumber = helpers.regex(
    'isPhoneNumber',
    /^\+?\d*$/
)